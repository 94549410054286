import './index.scss'
import Modal from '../../components/Modal'
import { useState } from 'react'
import Card from '../../components/Card'
import { data, skills } from '../../data/p'
import Accordion from '../../components/Accordion'
import { FaLinkedinIn } from 'react-icons/fa'
import { FiGithub } from 'react-icons/fi'
import AvatarCanvas from '../../components/Avatar Canvas'

const Home = () => {
  const dev = [...data.dev]
  const des = [...data.des]
  const [modalOpen, setModalOpen] = useState(false)
  const [modalView, setModalView] = useState('about')
  const [workView, setWorkView] = useState('dev')
  const [project, setProject] = useState(null)
  const videoUrl = 'https://minters.s3.us-west-2.amazonaws.com/beach_720.mp4'
  const mobileVideoUrl =
    'https://minters.s3.us-west-2.amazonaws.com/mobile_b.gif'
  // const imageUrl = 'https://minters.s3.us-west-2.amazonaws.com/DSC07844.jpg'
  // const mobileUrl = 'https://minters.s3.us-west-2.amazonaws.com/DSC07819.jpg'
  //const avatarUrl = '/images/radadz.glb'

  const handleProjectClick = i => {
    setModalView('project')
    setProject(i)
    setModalOpen(true)
  }

  const handlePhotoClick = () => {
    setModalOpen(true)
    setModalView('photo')
  }
  return (
    <div className='vw-100 test'>
      {workView !== 'photo' && (
        <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          project={project}
          projectType={workView}
          modalView={modalView}
        />
      )}
      <div className='hero'>
        {/* <img src={imageUrl} alt='yep' className='desktop-hero' />
        <img src={mobileUrl} alt='yep' className='mobile-hero' /> */}
        <video autoPlay loop muted playsInline className='desktop-hero'>
          <source src={videoUrl} type='video/mp4' />
        </video>
        <img src={mobileVideoUrl} alt='yep' className='mobile-hero' />
      </div>
      <div className='text-section'>
        <div className='w-100 cont'>
          <div className='w-100'>
            <h1 className='text-lg-h1'>adam botma</h1>
          </div>
          <div className='w-100 hero-test'>
            <div className='w-50 d-flex hide-on-mobile'>
              <a
                href='https://www.adz.wtf/bb'
                className='d-flex align-items-center justify-content-center'
              >
                <img
                  src='/images/invader.svg'
                  alt='space invader'
                  className='mr-3'
                />
              </a>
              <a
                href='#about'
                className='text-lg-h3 mono mr-4 text-black no-underline'
              >
                ABOUT
              </a>
              <a
                href='#work'
                className='text-lg-h3 mono mr-4 text-black no-underline'
              >
                WORK
              </a>
              <a
                href='#expterise'
                className='text-lg-h3 mono mr-4 text-black no-underline'
              >
                SKILLS
              </a>
              <a
                href='#contact'
                className='text-lg-h3 mono mr-4 text-black no-underline'
              >
                CONTACT
              </a>
            </div>

            <div className='d-flex mobile-cool-container'>
              <div className='d-flex mobile-cool'>
                <p className='text-lg-h3 mono'>DEVELOP + DESIGN</p>
              </div>

              <div className='d-flex justify-content-end hide-mobile mobile-cool2'>
                <a
                  href='https://www.linkedin.com/in/adam-botma/'
                  className='text-lg-h3 mono mr-4 text-black no-underline'
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href='https://github.com/adam-botma'
                  className='text-lg-h3 mono gh text-black no-underline'
                >
                  <FiGithub />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id='about'
        className='bg-primary w-100 p-4 d-flex'
        style={{ minHeight: '800px' }}
      >
        <div className='w-40 about-section'>
          <AvatarCanvas />
          {/* <Avatar
            animationSrc={avatarUrl}
            scale={1}
            cameraInitialDistance={10}
            ambientLightColor='#fff5b6'
            ambientLightIntensity={0.25}
            cameraTarget={1.65}
            dirLightColor='#002aff'
            dirLightIntensity={5}
            emotion={{
              browInnerUp: 0.3,
              browOuterUpLeft: 0.37,
              browOuterUpRight: 0.49,
              eyeSquintLeft: 0.4,
              eyeSquintRight: 0.2,
              mouthShrugUpper: 0.27,
              mouthSmileLeft: 0.37,
              mouthSmileRight: 0.36,
            }}
            spotLightAngle={0.314}
            spotLightColor='#fff5b6'
            spotLightIntensity={1}
            style={{
              background: 'transparent',
            }}
          /> */}
        </div>
        <div className='w-60 about-section'>
          <div className='w-100 h-10'>
            {/* <video className='video' autoPlay loop muted height={'20px'}>
              <source src='/images/Scribble_11.mp4' type='video/mp4' />
            </video> */}
          </div>
          <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
            <p className='text-lg-h4 mono text-white'>
              Hi! I'm a full-stack web developer who loves new and unique
              challenges. I have a wide variety of experience ranging from
              Ecommerce to web3 and blockchain technologies. I'm always
              interested in working with unique indivduals on fun and complex
              projects. Feel free to reachout to me if you are looking to hire
              or collab. I would love to hear about projects that you are in
              need of help on.
              {/* <span
                className='btn btn-link text-lg-h4 mono text-white'
                onClick={() => {
                  setModalView('about')
                  setModalOpen(true)
                }}
              >
                {`learn more >`}
              </span> */}
            </p>
          </div>
        </div>
      </div>
      <div id='work' className='w-100 p-4'>
        <div className='ml-2 w-100 d-flex justify-content-between'>
          <h2 className='text-lg-h1'>WORK</h2>
        </div>
        <div className='w-100 mt-3 ml-3 mb-5'>
          <ul className='text-lg-h3 d-flex'>
            <li
              onClick={() => setWorkView('dev')}
              className={`${workView === 'dev' ? 'feat' : null} clickable`}
            >
              DEVELOP
            </li>
            <li
              onClick={() => setWorkView('des')}
              className={`${workView === 'des' ? 'feat' : null} mx-3 clickable`}
            >
              DESIGN
            </li>
            {/* <li
              onClick={() => handlePhotoClick()}
              className={`${workView === 'photo' ? 'feat' : null} clickable`}
            >
              PHOTO */}
            {/* </li> */}
          </ul>
        </div>
        <div className='w-100'>
          {workView === 'dev' && (
            <div className='w-100'>
              <div className='row'>
                {dev.map((p, i) => (
                  <Card
                    key={i}
                    index={i}
                    image={p.image}
                    type={p.type}
                    title={p.title}
                    handleProjectClick={i => handleProjectClick(i)}
                  />
                ))}
              </div>
            </div>
          )}
          {workView === 'des' && (
            <div className='w-100'>
              <div className='row'>
                {des.map((p, i) => (
                  <Card
                    key={i}
                    index={i}
                    image={p.image}
                    type={p.type}
                    title={p.title}
                    handleProjectClick={i => handleProjectClick(i)}
                  />
                ))}
              </div>
            </div>
          )}
          {workView === 'photo' && <h2>coming soon</h2>}
        </div>
      </div>
      {/* <div id='extra-photo'>
        <div className='hero'>
          <video autoPlay loop muted>
            <source src={videoUrl} type='video/mp4' />
          </video>
        </div>
      </div> */}
      <div id='expterise' className='w-100 p-4'>
        <div className='w-100 d-flex justify-content-between'>
          <h2 className='text-lg-h1 mobile-90 lh-2'>Expertise</h2>
        </div>
        <div>
          <Accordion
            title='Frontend'
            content={skills.frontend.map(s => (
              <p className='text-lg-p2 mono mb-1'>{s.title}</p>
            ))}
          />
          <Accordion
            title='Backend'
            content={skills.backend.map(s => (
              <p className='text-lg-p2 mono mb-1'>{s.title}</p>
            ))}
          />
          <Accordion
            title='Shopify'
            content={skills.ecommerce.map(s => (
              <p className='text-lg-p2 mono mb-1'>{s.title}</p>
            ))}
          />
          {/*<Accordion
            title='Web3'
            content={skills.web3.map(s => (
              <p className='text-lg-p2 mono mb-1'>{s.title}</p>
            ))}
            /> */}
          <Accordion
            title='Tools'
            content={skills.tools.map(s => (
              <p className='text-lg-p2 mono mb-1'>{s.title}</p>
            ))}
          />
        </div>
      </div>
      <div id='contact' className='ct p-4'>
        <div className='contact-container p-4'>
          <h2 className='text-lg-h1 text-white mobile-90'>CONTACT</h2>
          <div className='w-100'>
            <ul>
              <li>
                <a
                  href='mailto:adam.botma@me.com'
                  className='text-lg-p1 mono text-white no-underline'
                >
                  adam.botma@me.com
                </a>
              </li>
              <li>
                <a
                  href='tel:8083212808'
                  className='text-lg-p1 mono text-white no-underline'
                >
                  808.321.2808
                </a>
              </li>
              <li>
                <a
                  href='https://www.linkedin.com/in/adam-botma/'
                  className='text-lg-p1 mono text-white no-underline'
                >
                  linkedin
                </a>
              </li>
              <li>
                <a
                  href='https://github.com/adam-botma/'
                  className='text-lg-p1 mono text-white no-underline'
                >
                  github
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
