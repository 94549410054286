export const data = {
  dev: [
    {
      title: 'Adventure Time',
      workFor: 'personal',
      description: 'AI Choose Your Own Adventure Game',
      role: 'Fullstack Engineer',
      live: 'https://adventuretime.cool',
      image: './images/advtime.png',
      link: '/thrivecausemtics',
      type: 'personal',
      demoVideos: 'demo-vids', // is the s3 bucket folder name containing the videos
      imageBank: [
        './images/advtime.png',
        './images/advtime_002.png',
        './images/advtime_003.png',
      ],
      details: `I loved reading choose your own adventure books as a kid and wanted to recreate the experience.  Adventure Time  is a choose your own andventure text-based game where you create a one of a kind story all your own. Each story is crafted by ai, creating a unique tale where the player's choices shape the outcomes, ensuring that no two stories are exactly alike!

Once the player clicks begin, they are asked to enter the genre they would like the story to be set in. This can be as simple as inputing "western" for a cowboy western adventure, or get as creative as they'd like - try maybe "anakin skywalker turned darth vader mowing down jedi during order 66"?

After selecting a genre, the story begins. They will be given the first part of the story, and several choices for them to choice from. If they select "view inventory", they will be presented with a screen displaying any items they may have collected during the journey.`,
    },
    {
      title: "Knowin' What's Growin'",
      workFor: 'FYC Labs',
      description: 'Learning application for California 4th graders',
      role: 'Fullstack Engineer',
      live: 'https://knowinwhatsgrowin.com',
      image: './images/kwg.png',
      link: '/thrivecausemtics',
      type: 'professional',
      demoVideos: 'demo-vids', // is the s3 bucket folder name containing the videos
      imageBank: [
        './images/kwg.png',
        './images/kwg-1.png',
        './images/kwg-2.png',
        './images/kwg-3.png',
        './images/kwg-4.png',
      ],
      details: `I had the opportunity to build a learning app that serves as an extension for 4th grade classroom curriculum. With Knowin' What's Growin, students can learn about crops that are locally grown throughout the state of California.

Features included in the app:

1. Students can navigate a state map of California. This was built using a combination of GeoJSON, react-simple-maps, and Preact Signals for state management.

2. Students can view lessons about crops, as well as view and print recipes that include the crop.

3. After completing lessons for all crops associated with a particular county, students are greeted with a quiz to test their skills. Quiz questions are a combination of multiple choice and matching types.

4. After completing a quiz for a county or a lesson for a crop, students earn badges to celebrate. Students can track their progress by viewing all earned badges in their profile.

5. Parents can navigate to the Resources page, where they can view all lessons and recipes. They can download generated PDFs for all lessons and recipes here. All PDFs are generated using a Google Cloud function that uses Node.js with the help of Puppeteer.

6. The build is a React headless setup, with all content for crops, counties, quizzes, and static pages being managed in a WordPress instance.`,
    },
    {
      title: 'Haiku Hugo',
      workFor: 'personal',
      description: 'An AI driven Haiku creator',
      role: 'Personal Project',
      live: 'https://www.haikuhugo.com',
      image: 'https://minters.s3.us-west-2.amazonaws.com/hh.gif',
      link: '/holostars',
      type: 'personal',
      imageBank: ['https://minters.s3.us-west-2.amazonaws.com/hh.gif'],
      details: `I was inspired to experiment with AI, leading me to develop a unique bot capable of crafting haikus based on any given subject. Once a haiku is generated, it's automatically posted to Twitter. The process involves an API endpoint that takes user input, prompts Chat GPT to compose a haiku on the specified topic, and then posts it using Twitter's API.`,
    },
    {
      title: 'Thrive Causemetics',
      workFor: 'Thrive Causemetics',
      description: 'Shopify Store + Internal Apps',
      role: 'Fullstack Engineer',
      live: 'https://www.thrivecausemetics.com',
      image: './images/thrivecausemetics.png',
      link: '/thrivecausemtics',
      type: 'professional',
      demoVideos: 'demo-vids', // is the s3 bucket folder name containing the videos
      imageBank: ['./images/thrivecausemetics.png'],
      details: `I had the privilege of joining the Ecommerce team at Thrive Causemetics, one of Shopify's premier stores and a leading D2C beauty brand. My tenure has seen the inception of numerous projects, redesigns, and integrations. Here are a few noteworthy contributions:

1. Link Builder: I developed a nifty internal Shopify application using React, Shopify's Polaris, Admin API, and Storefront API. This tool empowers our Customer Experience team to craft custom URLs for direct communication with clients. By leveraging URL parameters and Google Analytics, we can trace traffic and sales to specific team members, enhancing our workflow efficiency and bolstering customer experience.  I launched this within my first 30 days on the team. 

2. Free Gift with Purchase: At the behest of our Merchandising team, I implemented a multi-tier gift-with-purchase program. Utilizing the Contentful CMS as our data source, I integrated a modal into our checkout.liquid, enabling customers to select product variants (like makeup shades) or have single-variant items automatically added to their carts.

3. Shade Finder: To enhance the shopping experience, I devised a feature allowing customers to filter product shades based on various factors. This was achieved using Contentful CMS, Shopify Liquid, and a dash of Vanilla JavaScript.

4. Dual Branding: The company aspired to offer a distinctive online presence for a second brand. The challenge was to make this new brand stand out, yet allow shared cart functionality. We addressed this by introducing a tab navigation, directing customers to specific URLs for each brand. Each brand exudes its individuality through unique styles, fonts, and colors, toggled using CSS variables. A pivotal part of this branding was the development of a new product display page, boasting an image grid on desktops and a custom image slider for mobile devices.

5. Express API Server: I pioneered the creation of an express server, which is instrumental for various third-party integrations, such as Cordial, Postscript, and Yotpo. Additionally, it plays a pivotal role in interfacing with Shopify's Admin and Storefront APIs.

6. Rebuy Integration: Undertaking a comprehensive cart revamp, I successfully integrated the Rebuy Smart Cart using their Vue templates. Harmonizing their system with ours was no small feat. However, the results speak volumes – a revenue surge by $6,000,000 with a forecasted uptick of $30,000,000 for the year.`,
    },
    {
      title: 'Holostars',
      workFor: 'FYC Labs + Chainstarters',
      description: 'NFT Marketplace',
      role: 'Frontend Developer',
      live: 'https://www.holostars.com',
      image: './images/holostars.gif',
      link: '/holostars',
      type: 'professional',
      imageBank: ['./images/holostars.gif'],
      details: `* Note it appears the live version I had built has been converted into a Shopify store. *
      
      I had the distinct opportunity to lead the frontend development of an NFT Marketplace tailored for College Athletes. Harnessing a powerful blend of tools – React, ethers.js, useDapp, recharts, and Bootstrap – I turned vision into reality. While I delved into some design elements, my primary focus was on frontend development and the user authentication process. Collaborating closely with a teammate overseeing the backend, we provided users with the flexibility to sign up using either their Metamask wallet or a traditional email address. To cater to those opting for email sign-up, we hosted their wallet through Magic Link.

What stands out in this project for me is the minting experience we provided. It was not just about the technicalities, but about making it smooth and memorable for our users.

Favorite Feature: Magic Link's integration with various social profiles was a game-changer. I took advantage of this to introduce a unique feature where users could mint an NFT directly using their GitHub profile.`,
    },
    {
      title: 'Fordays',
      workFor: 'FYC Labs',
      description: 'Shopify Store + Custom App',
      role: 'Full Stack Engineer',
      live: 'https://www.fordays.com',
      image: './images/fordays.png',
      link: '/fordays',
      type: 'professional',
      imageBank: ['./images/fordays.png'],
      details: `One of my inaugural professional development endeavors was for the brand, Fordays. I played a pivotal role in crafting a contemporary Shopify Theme and was instrumental in architecting their user rewards system, "Closet Cash." This innovative system offered customers digital credits redeemable for gift cards, with two primary avenues to accrue these credits:

      1. Item-Based Earning: Every product had an associated Closet Cash value. Should a customer decide they no longer desired the item, they could return it to Fordays and receive credits equivalent to the item's value.

2. Take Back Bag: Customers had the option to purchase a Take Back Bag. They could fill this bag with washed, used clothing items and ship them back to Fordays for recycling. Based on the bag's size, customers would be rewarded with Closet Cash.

Leveraging a robust tech stack - React, Express, TypeScript, and Google Firebase Database - I meticulously designed the frontend experience, structured the database, and curated the journey for users procuring their Take Back Bag. A unique aspect was the integration of a QR code on each bag. Customers could swiftly scan the code to initiate the return, and our shipping and receiving teams utilized the same codes to process the items seamlessly.

My contribution didn't end there. I spearheaded multiple sales promotions during Black Friday, Cyber Monday, and the festive season, propelling Fordays to its most prosperous holiday sales period to date. These promotions encompassed:

- Mystery discounts
- Tiered free gifts based on cart value
- Custom slash discounted promotions
- Gated sales experiences

Favorite Feature: The QR code-based scanning and the complete Take Back Bag redemption journey.
`,
    },
    {
      title: 'BA Kanban',
      workFor: 'MintBean Javascript Olympics Hackathon',
      description:
        'BadASS Kanban is a web application for users to track their progress on tasks for a project.',
      type: 'hackathon',
      role: 'Participant',
      github: 'https://github.com/adam-botma/hackathon-badass',
      live: 'https://demo-badass-kanban.adambotma.xyz/',
      image: './images/ba-kanban.gif',
      link: '/ba-kanban',
      imageBank: ['./images/ba-kanban.gif'],
      details: `At a 48-hour hackathon by Mint Bean, we faced the challenge of crafting a kanban board using only frontend technologies. Leveraging React, local storage, Material UI, and a few npm packages, our team clinched 2nd place among a multitude of international teams.

Favorite Feature: I incorporated a confetti animation for completed tasks, which might have given us an edge for that 2nd place finish.`,
    },
    {
      title: 'Code Notes',
      description:
        'A dynamic web application for students learning to code who want to organize their notes, documentation resources, flashcards, and code examples all in one place.',
      type: 'personal',
      role: 'Personal Project',
      github: 'https://github.com/adam-botma/class-notes',
      live: 'https://code-notes.adambotma.xyz/',
      image: './images/code-notes.png',
      link: '/code-notes',
      imageBank: [
        'https://user-images.githubusercontent.com/61361957/98852563-b2a07600-240c-11eb-86db-9f7855a2576d.gif',
      ],
      details: ``,
    },
    // {
    //   title: 'Sol Sunglasses',
    //   description: 'A full stack Node.js and React shopping cart application',
    //   role: 'Personal Project',
    //   type: 'personal',
    //   github: 'https://github.com/adam-botma/sol-sunglasses-js',
    //   live: 'https://sol-sunglasses.adambotma.com/',
    //   image: './images/sol-sunglasses.gif',
    //   link: '/sol-sunglasses',
    //   imageBank: ['./images/sol-sunglasses.gif'],
    //   details: `Diving deep into the world of e-commerce, I embarked on a nostalgic trip to deconstruct the magic behind my old Shopify store. Enter 'Sol Sunglasses' - not your everyday online shop! Crafted with React, Express, and Postgres, this platform is purely an enlightening experiment (so no, those snazzy shades aren’t up for grabs). And for an extra personal touch? Those captivating hero images greeting you on the homepage? They’re all snapshots from my own camera adventures over the years.`,
    // },
    {
      title: 'R + M Battle Royal',
      role: 'Personal Project',
      type: 'personal',
      description:
        'A hackathon project utilizing the data from two public APIs to create a card game similar to classic War.',
      github: 'https://github.com/adam-botma/rm-battle-royale',
      live: 'https://rm-battle-royale.adambotma.xyz/',
      image: './images/Rick-Morty-battle.png',
      link: '/rick-morty',
      imageBank: [
        './images/Rick-Morty-battle.png',
        'https://minters.s3.us-west-2.amazonaws.com/rm-splash.png',
        'https://minters.s3.us-west-2.amazonaws.com/rmplay.gif',
      ],
      details: ``,
    },
    {
      title: 'Learn Hawaiian Memory Match',
      role: 'Personal Project',
      type: 'personal',
      description:
        'A simple card match game where players match English words with their Hawaiian counterpart',
      github: 'https://github.com/adam-botma/learn-hawaiian-memory-match',
      live: 'https://memory-match.adambotma.xyz/',
      image: 'https://minters.s3.us-west-2.amazonaws.com/mm2.gif',
      link: '/rick-morty',
      imageBank: [
        'https://raw.githubusercontent.com/adam-botma/learn-hawaiian-memory-match/master/assets/images/hawaii-mem-match-sml.gif',
        'https://raw.githubusercontent.com/adam-botma/learn-hawaiian-memory-match/master/assets/images/Instructions.png',
        'https://minters.s3.us-west-2.amazonaws.com/game-demo.gif',
      ],
      details: `A fun memory match card game where users are required to match a card containg an English word (and helpful image) with the corresponding Hawaiian word card. To help users learn which cards match, each matching card set has the same colored background and icon image.`,
    },
  ],

  des: [
    {
      title: 'XIX Palms',
      description: 'Complete Branding, Creative Direction, and more',
      type: 'Creative Direction',
      live: 'https://www.xixpalms.com/',
      image: '/images/xixpalms-im1.jpg',
      link: '/xixpalms',
      imageBank: [
        '/images/xixpalms-im1.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/adz-xix-1.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/nellie-1-160.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/trees-1022.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/nora.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/xix105.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/xix104.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/xix102.jpg',
        'https://minters.s3.us-west-2.amazonaws.com/adz-xix-2.jpg',
      ],
      details: `From a scribble on paper to a fashion sensation, I transformed an idea into a women's clothing line that was in over 110 retail doors across the U.S., Canada, Japan, and Australia. The e-commerce wing bloomed from its infancy to constitute a whopping 70% of our business.

Navigating the unpredictable tides of entrepreneurship, my roles varied. Whether it was sketching designs, capturing our brand's essence through a lens, or even juggling numbers to strategize prices, my hands were full.

Here’s a few of the details:

-Developed the overall brand strategy including all logos, brand story, and maintained the overall creative direction of the brand.
-2015 - 2016 growth of 2.74x with GM of 52% and Profit of 10.7%
-2016-2017 growth of 3.5 x with GM of 81% and Profit of 31%
-2017-2018 growth of 1.8x with GM of 65% and Profit of 15% 
-Developed an overall pricing strategy to maintain high Gross Margins while still staying competitive in the market. 
-Built an Instagram Following from zero to 40k at the time of leaving.
-Built an email list from zero to approx. 10k active subscribers.
-Designed, tracked, and analyzed all Email marketing campaigns through MailChimp.
-Built a wholesale business that covered retail locations throughout the U.S., Canada, Australia, and Japan. Major Accounts included: Anthropologie, Everything But Water, and United Arrows (Japan).
-Managed 4 different sales reps over 3 separate U.S. Territories and 1 Australian Territory. 
-Developed Ecommerce sites on both Wordpress and Shopify. Facilitated multiple redesigns to optimize customer conversions. 
-Handled all product and lifestyle photography for the brand’s website. 
-Worked with Major Instagram influencers on Collaboration Products that were sold on the brand’s website.
`,
    },
    {
      title: 'Ultimate Water Creations',
      workFor: 'Houzz',
      description: 'Company Website Built for Luxury Pool Builder',
      type: 'Design',
      live: 'https://ultimatewatercreations.net/',
      image: './images/ultimatewatercreations.jpg',
      link: '/design',
      imageBank: [
        'https://pace-pusher.s3-us-west-1.amazonaws.com/ultimatewatercreations.png',
      ],
      details: `A website I designed while working at Houzz. My job their was to jump on Zoom calls with Houzz Pro users, understand what they were trying to accomplish with their website, and design one within in our internal website builder. This was for a customer who designed pools who built high end custom pools for families in Los Angeles.`,
    },
    {
      title: 'Jenny Guza Designs',
      workFor: 'Houzz',
      description: 'Company Website Built for Interior Designer',
      type: 'Design',
      live: 'https://jennyguzadesigns.com/',
      image: './images/jenny-2.jpg',
      link: '/design',
      imageBank: ['https://pace-pusher.s3-us-west-1.amazonaws.com/jenny-2.png'],
      details: `A website I designed while working at Houzz. My job their was to jump on Zoom calls with Houzz Pro users, understand what they were trying to accomplish with their website, and design one within in our internal website builder. This was for a customer who designed homes for families in Tenessee.`,
    },
    {
      title: 'Morgan HR',
      workFor: 'FYC Labs',
      description: 'Company Website Built for HR Consulting Firm',
      type: 'Design',
      live: 'https://morganhr.com/',
      image: '/images/morganHR.png',
      link: '/design',
      imageBank: ['/images/morganHR.png'],
      details: `This was a Wordpress site that I designed for a Human Resources Consulting Firm.  They have several brands and wanted a cohesive story and website that showed how all three brands worked together.`,
    },
  ],
}

export const skills = {
  frontend: [
    {
      title: 'HTML5',
    },
    {
      title: 'CSS3',
    },
    {
      title: 'Javascript',
    },
    {
      title: 'Liquid',
    },
    {
      title: 'React',
    },
    {
      title: 'Bootstrap',
    },
    {
      title: 'Material UI',
    },
    {
      title: 'Tailwind CSS',
    },
    {
      title: 'Three.js',
    },
    {
      title: 'Ethers.js',
    },
  ],
  backend: [
    {
      title: 'Node.js',
    },
    {
      title: 'Express',
    },
    {
      title: 'Rust',
    },

    {
      title: 'Graphql',
    },
    {
      title: 'PostgreSQL',
    },
    {
      title: 'MongoDB',
    },
    {
      title: 'Google Firebase',
    },
    {
      title: 'Shopify Plus',
    },
    {
      title: '3rd Party Integrations',
    },
    {
      title: 'Solidity',
    },
    {
      title: 'Ethers.js',
    },
    {
      title: 'hardhat',
    },
    {
      title: 'IFPS / Piñata',
    },
  ],
  tools: [
    {
      title: 'Github',
    },
    {
      title: 'AWS',
    },
    {
      title: 'VS Code',
    },
    {
      title: 'Ubuntu',
    },
    {
      title: 'Nginx',
    },
    {
      title: 'Photoshop',
    },
    {
      title: 'Lightroom',
    },
    {
      title: 'Midjourney',
    },
    {
      title: 'Figma',
    },
    {
      title: 'Heroku',
    },
    {
      title: 'Netlify',
    },
  ],
  ecommerce: [
    {
      title: 'Liquid',
    },
    {
      title: 'Admin Api',
    },
    {
      title: 'Storefront Api',
    },
    {
      title: 'Cart Scripts (Ruby)',
    },
    {
      title: 'Checkout UI Extensions',
    },
    {
      title: 'Shopify Functions',
    },
    {
      title: 'Custom CMS Integrations',
    },
    {
      title: 'Google Tag Manager',
    },
    {
      title: 'Google Analytics',
    },
    {
      title: 'Octane AI',
    },
    {
      title: 'Yotpo',
    },
    {
      title: 'ERP Integrations',
    },
    {
      title: 'Klaviyo',
    },
    {
      title: 'Recharge',
    },
    {
      title: 'Rebuy',
    },
  ],
}
